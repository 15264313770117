import React from 'react';
import {ThemeProvider, CssBaseline, createTheme} from '@mui/material';
import {
  QueryClientProvider,
  QueryClient,
  MutationCache,
  QueryCache,
} from '@tanstack/react-query';
import {AuthProvider} from './contexts/useAuthContext/useAuthContext';
import {Toaster, toast} from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import GlobalStyles from '@mui/material/GlobalStyles';
import Layout from './layout/Layout';

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        margin: 0,
        fontFamily: 'Rubik',
        fontWeight: 400,
        fontStyle: 'normal',
      },
    }}
  />
);

const App: React.FC = () => {
  const {t} = useTranslation();
  const postQuerriesError = ['setAuth', 'signUp'];
  const postQuerriesSuccess = ['signUp'];

  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onSuccess: (message: string) => {
        if (typeof message != 'string') {
          return;
        }
        toast.success(message ?? t('DEFAULT-SUCCESS-MESSAGE', {ns: 'api'}));
      },
      onError: error => {
        toast.error(error.message ?? t('DEFAULT-ERROR-MESSAGE', {ns: 'api'}));
      },
    }),
    queryCache: new QueryCache({
      onSuccess: (message: string, query) => {
        if (
          postQuerriesSuccess.includes((query.queryKey as Array<string>)[0])
        ) {
          toast.success(message ?? t('DEFAULT-SUCCESS-MESSAGE', {ns: 'api'}));
        }
      },
      onError: (error, query) => {
        if (postQuerriesError.includes((query.queryKey as Array<string>)[0])) {
          toast.error(error.message ?? t('DEFAULT-ERROR-MESSAGE', {ns: 'api'}));
        }
      },
    }),
  });

  let theme = createTheme();
  theme = createTheme(theme, {
    palette: {
      primary: {
        light: '#1ff9d1',
        main: '#3a83f0',
        dark: '#0d54bf',
        contrastText: '#fff',
      },
      secondary: {
        light: '#dab557',
        main: '#d1a32e',
        dark: '#a78225',
        contrastText: '#fff',
      },
      info: {
        main: '#fff',
        contrastText: '#000',
      },
      background: {
        default: '#f4f9fd',
      },
      error: {
        main: '#d4180b',
        dark: '#cf170a',
        contrastText: '#fff',
      },
    },

    typography: {
      fontFamily: 'Rubik',
      h1: {
        fontWeight: 700,
        fontSize: theme.breakpoints.up('md') ? '3em' : '2em',
        color: '#000',
        paddingBottom: '30px',
      },
      h2: {
        fontWeight: 700,
        fontSize: theme.breakpoints.up('md') ? '2em' : '1.5em',
      },
      h3: {
        fontWeight: 'bold',
        fontSize: theme.breakpoints.up('md') ? '1.5em' : '1.3em',
      },
      h4: {
        fontWeight: 'bold',
        fontSize: theme.breakpoints.up('md') ? '1.3em' : '1.2em',
      },
      h5: {
        fontSize: theme.breakpoints.up('md') ? '1.3em' : '1.2em',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          body1: {
            fontFamily: 'Rubik, sans-serif',
          },
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        <AuthProvider>
          <Layout />
          <Toaster />
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
