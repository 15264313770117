export const API_CODES = [
  'BAD-CREDENTIALS',
  'CREDENTIALS-EXPIRED',
  'ACCOUNT-LOCKED',
  'JWT-BLACKLISTED',
  'INVALID-FIELDS',
  'USERNAME-TAKEN',
  'EMAIL-TAKEN',
  'SIGNUP-SUCCESS',
  'LOGOUT-SUCCESS',
  'ROLE-REQUIRED',
  'UNSUPPORTED-ROLE',
  'ROLE-NOT-FOUND',
  'USER-NOT-FOUND',
  'CLIENT-NOT-FOUND',
  'CLIENTDETAILS-NOT-FOUND',
  'WORKORDER-ALREADY-SIGNED',
];
