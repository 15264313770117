import React, {useState} from 'react';
import {
  Table,
  TableCell,
  Button,
  WorkOrderModal,
  SignatureModal,
} from '../../components';
import {useTranslation} from 'react-i18next';
import {
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  getWorkOrders,
  useAddWOSignature,
  useDeleteWorkOrder,
  useGetWOReport,
} from '../../service/WorkOrderService/useWorkOrderService';
import {useAuthContext} from '../../contexts/useAuthContext/useAuthContext';
import {useNavigate} from 'react-router-dom';
import {getFormatedDate} from '../../utils/dateUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {DeleteModal} from '../../components';
import {Status} from '../../service/types';
import AddIcon from '@mui/icons-material/Add';
import {useCreateWorkOrder} from '../../service/WorkOrderService/useWorkOrderService';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import {ItemWO} from '../../service/WorkOrderItemsService/types';

const Index = () => {
  const {t} = useTranslation();
  const {isAdmin} = useAuthContext();
  const navigate = useNavigate();

  const [selectedDeleteId, setSelectedDeleteId] = useState<number | undefined>(
    undefined,
  );

  const [selectedSignature, setSelectedSignature] = useState<
    ItemWO | undefined
  >(undefined);

  const [openWOModal, setOpenWOModal] = useState(false);

  //actions
  const {deleteWorkOrder, deleteStatus} = useDeleteWorkOrder(selectedDeleteId);
  const {createStatus, createWorkOrder} = useCreateWorkOrder();
  const {
    isPending: isPendingUpload,
    addWOSignature,
    status: signatureStatus,
  } = useAddWOSignature(selectedSignature?.id);

  const {getWOReport} = useGetWOReport();

  const handleRowClick = (params: GridRowParams) => {
    navigate(`/workorders/${params?.row?.id}`);
  };

  let columns: GridColDef[] = [
    {
      field: 'workOrderNumber',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          firstText={params?.row?.workOrderNumber}
          secondText={`${t('WOTableOpendDate')} ${getFormatedDate(params?.row?.openedDate)}`}
          isFirstTextBold={true}
          sx={{main: {paddingLeft: '30px'}}}
          showTooltip={true}
        />
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      maxWidth: 200,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'client',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          firstText={params?.row?.client?.name}
          secondText={params?.row?.device?.name}
          sx={{main: {paddingLeft: '30px'}}}
          isFirstTextBold={true}
          showTooltip={true}
        />
      ),
      flex: 1,
      align: 'center',
      minWidth: 180,
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'closed',
      renderCell: (params: GridRenderCellParams) => (
        <TableCell
          firstText={t('WOTableClosedDate')}
          secondText={
            !params?.row?.closedDate
              ? t('no')
              : getFormatedDate(params?.row?.closedDate)
          }
          sx={{main: {paddingLeft: '30px'}}}
        />
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      maxWidth: 150,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  if (isAdmin) {
    columns = [
      ...columns,
      {
        field: 'asignedTo',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell
            firstText={t('WOTableAssignedTo')}
            secondText={`${params?.row?.user?.firstName} ${params?.row?.user?.lastName}`}
            isReplaceColors={true}
            isFirstTextBold={true}
            sx={{main: {paddingLeft: '30px'}}}
            showTooltip={true}
          />
        ),
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        maxWidth: 270,
        disableColumnMenu: true,
        sortable: false,
      },
    ];
  }

  columns = [
    ...columns,
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      minWidth: 100,
      maxWidth: 250,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => getWOReport(params?.row?.id)}>
            <PictureAsPdfIcon color="primary" />
          </IconButton>
          <IconButton
            onClick={() => setSelectedDeleteId(params?.row?.id)}
            disabled={!params?.row?.closedDate ? false : true}>
            <DeleteIcon
              color={!params?.row?.closedDate ? 'primary' : 'disabled'}
            />
          </IconButton>
          <IconButton
            onClick={() =>
              setSelectedSignature({
                id: params?.row?.id,
                workOrderNumber: params?.row?.workOrderNumber,
              } as ItemWO)
            }
            disabled={params?.row?.signed}>
            <DrawOutlinedIcon
              color={!params?.row?.signed ? 'primary' : 'disabled'}
            />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Grid container display={'flex'} direction={'column'}>
      <Grid item sx={titleCss}>
        <Typography variant="h1">{t('WOTableTitle')}</Typography>
      </Grid>
      <Grid container display={'flex'} sx={mainGridCss} direction={'column'}>
        <Grid item alignSelf={'flex-end'} sx={buttonGridCss}>
          <Button
            id="add-WO"
            startIcon={<AddIcon />}
            text={t('addWOBtn')}
            onClick={() => setOpenWOModal(true)}
          />
        </Grid>
        <Grid item>
          <Table
            queryFn={getWorkOrders}
            columns={columns}
            onRowClick={handleRowClick}
            tableDeleteRow={{
              status: deleteStatus as Status,
              afterSuccessCallback: () => setSelectedDeleteId(undefined),
            }}
            tableUpdateRow={{
              status: signatureStatus as Status,
              afterSuccessCallback: () => setSelectedSignature(undefined),
            }}
            tableCreateRow={{
              status: createStatus as Status,
              afterSuccessCallback: () => setOpenWOModal(false),
            }}
          />
        </Grid>
      </Grid>
      {selectedDeleteId && (
        <DeleteModal
          title={t('deleteWOTitle')}
          message={t('deleteWOMessage')}
          open={selectedDeleteId ? true : false}
          handleDelete={deleteWorkOrder}
          handleClose={() => {
            setSelectedDeleteId(undefined);
          }}
        />
      )}
      {openWOModal && (
        <WorkOrderModal
          id="WO-modal"
          title={t('WOModalAddTitle')}
          open={openWOModal}
          handleClose={() => {
            setOpenWOModal(false);
          }}
          handleSubmit={createWorkOrder}
        />
      )}
      {selectedSignature?.id && (
        <SignatureModal
          title={
            t('WOModalSignatureTitle') + ' ' + selectedSignature.workOrderNumber
          }
          open={selectedSignature.id ? true : false}
          signWO={addWOSignature}
          isPending={isPendingUpload}
          handleClose={() => {
            setSelectedSignature(undefined);
          }}
        />
      )}
    </Grid>
  );
};

const mainGridCss = {
  width: {xs: '95vw', md: '70vw'},
  alignSelf: 'center',
};

const titleCss = {
  alignSelf: 'center',
};

const buttonGridCss = {paddingRight: '50px'};

export default Index;
