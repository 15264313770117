import React, {useRef} from 'react';
import Box from '@mui/system/Box';
import {Dialog} from '../..';
import {useTranslation} from 'react-i18next';
import {ReactSketchCanvas, ReactSketchCanvasRef} from 'react-sketch-canvas';
import {Button} from '../../../components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {toast} from 'react-hot-toast';

interface Props {
  title: string;
  open: boolean;
  isPending: boolean;
  signWO: (file: File) => void;
  handleClose: () => void;
}

const Index: React.FC<Props> = ({
  title,
  open,
  isPending,
  signWO,
  handleClose,
}) => {
  const {t} = useTranslation();
  const canvasRef = useRef<ReactSketchCanvasRef>(null);

  const HandleClearClick = () => {
    canvasRef.current?.clearCanvas();
  };

  const HandleSubmitClick = async () => {
    try {
      const dataUrl = await canvasRef.current?.exportImage('png');
      const image = await fetch(dataUrl);
      const blob = await image.blob();
      if (blob.size == 5576) {
        toast.error(t('SIGNATURE_ERROR', {ns: 'api'}));
      } else {
        const file = new File([blob], 'signature.png');
        signWO(file);
      }
    } catch {
      toast.error(t('DEFAULT-ERROR-MESSAGE', {ns: 'api'}));
    }
  };

  return (
    <Dialog
      id="dialog-signature"
      fullWidth={true}
      fullWidthSx={'md'}
      open={open}
      title={title}
      handleClose={handleClose}
      secondButtonTitle={t('addSignature')}
      secondButtonDisabled={isPending}
      handleSecondButtonClick={HandleSubmitClick}>
      <Box>
        <Button
          id="clear-signature"
          startIcon={<DeleteOutlineIcon />}
          text={t('clearSignature')}
          disabled={isPending}
          onClick={HandleClearClick}
        />
        <Box sx={h20rem}>
          <ReactSketchCanvas
            style={canvasCss}
            width="100%"
            height="100%"
            ref={canvasRef}
            canvasColor="transparent"
            strokeWidth={2}
            strokeColor="black"
          />
        </Box>
      </Box>
    </Dialog>
  );
};

const h20rem = {
  height: '20rem !important',
};

const canvasCss = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.50rem',
};

export default Index;
