import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
  httpsAuthorized,
  getTranslationErrorCode,
} from '../common';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {Client, ClientDetails, ClientList, ClientListModal} from './types';
import {PaginationModel, TableData, SearchCriteria} from '../types';
import {TFunction} from 'i18next';
import {SelectItem} from '../../components/common/Select/types';

export const getClients = async (
  t: TFunction,
  paginationModel: PaginationModel,
  searchText: string,
) => {
  const queryParams = `?page=${paginationModel.page + 1}&size=${paginationModel.pageSize}`;
  const result = await httpPost<SearchCriteria>(
    `client/all${queryParams}`,
    {
      key: 'name',
      operation: 'CONTAINS',
      value: searchText,
    },
    t,
    false,
  );
  return result as TableData;
};

export const useGetClientList = () => {
  const {t} = useTranslation('api');
  const {
    refetch: getClientsList,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['ClientList'],
    queryFn: async () => {
      try {
        const {data} = await httpsAuthorized.post<ClientList>('client/all', {
          key: 'name',
          operation: 'BEGINS',
          value: '',
        });
        const selectValues: SelectItem[] = [];
        data?.content?.forEach((client: Client) => {
          selectValues.push({label: client.name, value: client.id.toString()});
        });
        return selectValues;
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    retry: 0,
  });

  return {
    getClientsList,
    data,
    isLoading,
  };
};

export const useGetClientListModal = () => {
  const {t} = useTranslation('api');
  const {
    refetch: getClientsListModal,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['ClientListModal'],
    queryFn: async () => {
      try {
        const {data} =
          await httpsAuthorized.get<ClientListModal>('client/list');
        const selectValues: SelectItem[] = [];
        data?.content?.forEach((client: Client) => {
          selectValues.push({label: client.name, value: client.id.toString()});
        });
        return selectValues;
      } catch (error) {
        return Promise.reject(
          new Error(t(getTranslationErrorCode(error), {ns: 'api'})),
        );
      }
    },
    retry: 0,
  });

  return {
    getClientsListModal,
    data,
    isLoading,
  };
};

export const useGetClientById = (id: string) => {
  const {t} = useTranslation('api');
  const {
    refetch: getClientById,
    data,
    isLoading,
  } = useQuery({
    queryKey: ['ClientById', id],
    queryFn: async () => {
      return await httpGet<Client>(`client/${id}`, t);
    },
    retry: 0,
  });

  return {
    getClientById,
    data,
    isLoading,
  };
};

export const useUpdateClient = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateClientById,
    data,
    isPending,
    isSuccess: isSuccessUpdateClient,
    status: updateStatus,
  } = useMutation({
    mutationFn: async (value: Client) => {
      return await httpPut<Client>(`client/${id}`, value, t);
    },
    retry: 0,
  });

  return {
    updateClientById,
    data,
    isPending,
    isSuccessUpdateClient,
    updateStatus,
  };
};

export const useUpdateClientDetails = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: updateClientDetails,
    data,
    isPending,
    isSuccess: isSuccessUpdate,
  } = useMutation({
    mutationFn: async (value: ClientDetails) => {
      return await httpPut<ClientDetails>(
        `client/client-details/${id}`,
        value,
        t,
      );
    },
    retry: 0,
  });

  return {
    updateClientDetails,
    data,
    isPending,
    isSuccessUpdate,
  };
};

export const useDeleteClientById = (id: number) => {
  const {t} = useTranslation('api');
  const {mutate: deleteClientById, status: deleteStatus} = useMutation({
    mutationFn: async () => {
      return await httpDelete(`client/${id}`, t);
    },
    retry: 0,
  });

  return {
    deleteClientById,
    deleteStatus,
  };
};

export const useDeleteClientDetails = (id: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: deleteClientDetails,
    isPending,
    isSuccess: isSuccessDelete,
  } = useMutation({
    mutationFn: async () => {
      return await httpDelete(`client/client-details/${id}`, t);
    },
    retry: 0,
  });

  return {
    deleteClientDetails,
    isPending,
    isSuccessDelete,
  };
};

export const useCreateClient = () => {
  const {t} = useTranslation('api');
  const {
    mutate: createClient,
    data,
    status: createStatus,
  } = useMutation({
    mutationFn: async (value: Client) => {
      return await httpPost<Client>('client', value, t);
    },
    retry: 0,
  });

  return {
    createClient,
    data,
    createStatus,
  };
};

export const useCreateClientDetails = (clientId: number) => {
  const {t} = useTranslation('api');
  const {
    mutate: createClientDetails,
    data,
    isPending,
    isSuccess: isSuccessCreate,
  } = useMutation({
    mutationFn: async (value: ClientDetails) => {
      return await httpPost<ClientDetails>(
        `client/client-details/${clientId}`,
        value,
        t,
      );
    },
    retry: 0,
  });

  return {
    createClientDetails,
    data,
    isPending,
    isSuccessCreate,
  };
};
